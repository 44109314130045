
$app-page-padding-x: 25px;
$app-page-padding-y: 25px;

.app {
  padding: $app-page-padding-y $app-page-padding-x 0 $app-page-padding-x;
  background: var(--card-background);
  border: 1px solid var(--card-border-color);
  border-radius: 13px;
  margin-top: 16px;
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;

  &__delimiter {
    margin: 32px (-$app-page-padding-x) 0 (-$app-page-padding-x);
  }

  &__info {
    margin: 32px 0;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    padding-right: 40px;
    gap: 50px;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__open-btn--wide {
    margin-top: 24px;
    margin-bottom: 20px;

    & button {
      text-transform: uppercase;
    }
  }
}

@media screen and (max-width: 480px) {
  $app-page-mobile-padding-x: 16px;
  $app-page-mobile-padding-y: 24px;

  .app {
    padding: $app-page-mobile-padding-y $app-page-mobile-padding-x 0
      $app-page-mobile-padding-x;
    border: none;
    border-radius: 0;
    margin-left: -$app-page-mobile-padding-x;
    margin-top: 12px;
    width: 100%;
    border-top: 1px solid var(--card-border-color);
    border-bottom: 1px solid var(--card-border-color);

    &__delimiter {
      margin: $app-page-mobile-padding-y -16px $app-page-mobile-padding-y -16px;
    }

    &__screenshots {
      padding-right: 16px;
    }
  }
}
