
.t-breadcrumbs {
  display: flex;
  gap: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
  overflow-x: overlay;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.t-breadcrumbs-crumb {
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--breadcrumbs-text-color);

  :last-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  a,
  span {
    color: var(--breadcrumbs-text-color);
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s;
  }

  a:hover {
    color: var(--body-text-color);
    text-decoration: none;
  }

  &__chevron {
    color: var(--breadcrumbs-text-color);
    width: 5px;
    height: 9px;
  }

  &--selected span {
    color: var(--body-text-color);
    cursor: default;
  }
}
