
.page-apps-not-found-kitty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: var(--apps-navigation-inactive-color);
  height: 240px;

  &__icon {
    margin: 0 auto;
    width: 150px;
  }

  &__text {
    font-size: 16px;
    font-weight: 400;
    color: var(--apps-navigation-inactive-color);
  }
}
