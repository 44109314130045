
.header {
  display: flex;
  background: var(--header-background);
  box-shadow: 0 1px 10px var(--header-box-shadow-color);
  padding: 8px 0;
  margin-bottom: 18px;
  height: 55px; // 54 + border
  border-bottom: 1px solid var(--header-border-bottom-color);
  box-sizing: border-box;
  position: relative;
  z-index: 80000;

  &__container {
    flex-grow: 1;
    max-width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    width: 46px;
    height: 46px;
    margin: -10px 0;
    position: relative;
    bottom: -1px;
    color: var(--header-logo-color);
    transition: 0.1s color ease;
    &:hover {
      color: var(--body-text-color);
    }

    .header--mobile-search-visible & {
      display: none;
    }
  }
}

.header-ear-left {
  //
}

.header-ear-right {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  &__buttons {
    display: flex;
    align-items: center;
  }
  &__button {
    cursor: pointer;
    color: #797979;
    padding: 16px 4px 16px 12px;
    margin: -12px -2px;
    fill: currentColor;
    width: 24px;
    height: 24px;
    &:hover {
      color: #444;
    }

    &--loopa {
      display: none;
      z-index: 80010;
    }
    &--settings {
      z-index: 80001;
    }
  }
}

@media screen and (max-width: 1200px) {
  .header__container {
    padding: 0 8px;
  }
}

.header-search {
  position: relative;
  width: auto;
  font-size: 14px;

  .search-input {
    min-width: 340px;
    background: var(--body-light-muted-color);
    color: var(--body-text-color);
    border-radius: 6px;
    border: 2px solid transparent;
    transition: 0.12s min-width ease, 0.12s border-color ease;
    &__loopa {
      margin: 0 -8px 0 8px;
      opacity: 0.3;
    }
    &__input {
      padding: 9px 0 9px 16px;
      &::placeholder {
        color: #939394;
      }
      &::-webkit-input-placeholder {
        color: #939394;
      }
    }
    &--expanded {
      min-width: 480px;
    }
    &:focus-within {
      min-width: 480px;
      outline: 0 none;
    }
  }
  .search-input-controls {
    &__loader {
      transform: scale(0.9);
    }
  }

  .search-results {
    padding-bottom: 4px;
    padding-top: 4px;
    overflow: hidden;
    &__link {
      margin: 0 0 0 0;
    }
  }
  .search-result {
    &__left {
      margin-left: -4px;
    }
  }
}

@media screen and (max-width: 480px) {
  .header {
    margin-bottom: 12px;
  }

  .header-search {
    flex-grow: 1;
    .search-input {
      width: 100%;
      max-width: 100%;
      min-width: 0;
      &:focus-within {
        max-width: 100%;
        width: 100%;
        min-width: 0;
      }
    }
  }

  // Small screen: hide search input UNLESS user clicked the loopa button:
  .header-search {
    display: none;

    .header--mobile-search-visible & {
      display: flex !important;
    }
  }

  .header-ear-right {
    // Small screen - hide right ear buttons IF the search field is active:
    &__buttons {
      .header--mobile-search-visible & {
        display: none;
      }
    }

    // Small screen - uncover loopa button:
    &__button {
      &--loopa {
        display: flex;
      }
    }
  }
}
